import {getRandomItem} from "@/custom/class/FunctionClasses/HelperFunctions.js"

export default class userHints {
  constructor() {
      this.items = [
        {
          id: 1,
          content: [
            {default: "You can also"},
            {success: "reorder Agents"},
            {default: "using the "},
            {success: "Rearrangement Overlay"},
          ],
        },
        {
          id: 2,
          content: [
            {success: "Webhooks "},
            {default: "can be run either"},
            {success: "synchornously"},
            {default: "(await for a response) or "},
            {success: "asynchronously"},
            {default: "(don't wait for it to respond)"},
          ],
        },
        {
          id: 3,
          content: [
            {default: "You can"},
            {success: "export Logs"},
            {default: "as CSV files"},
          ],
        },
        {
          id: 4,
          content: [
            {default: "You can"},
            {success: "Rollback"},
            {default: "a"},
            {success: "Transmissions"},
            {default: "to how it was on a given"},
            {success: "Script"}
          ],
        },
        {
          id: 5,
          content: [
            {success: "Exported Middlewares"},
            {default: "can be "},
            {success: "Downloaded"},
            {default: "and re-used later"}
          ],
        },
        {
          id: 6,
          content: [
            {default: "You can set"},
            {success: "Middlewares"},
            {default: "as"},
            {success: "Fatal"},
            {default: "if their execution is essencial to your Flux"}
          ],
        },
        {
          id: 7,
          content: [
            {default: "when a "},
            {success: "Javascript Agent"},
            {default: "runs into an "},
            {success: "error"},
            {default: "every operation executed by it is"},
            {success: "completely revoked"},
          ],
        },
        {
          id: 8,
          content: [
            {default: "If you set a "},
            {success: "Webhook"},
            {default: "to"},
            {success: "Merge"},
            {default: "you can"},
            {success: "fully customize"},
            {default: "its"},
            {success: "Return"}
          ],
        },
        {
          id: 9,
          content: [
            {default: "The "},
            {success: "Test Terminal"},
            {default: "is "},
            {success: "fully isolated"},
            {default: "from other"},
            {success: "Flux executions"},
          ],
        },
        {
          id: 10,
          content: [
            {success: "FiqOn"},
            {default: "is completely"},
            {success: "waterproof"},
          ],
        },
        {
          id: 11,
          content: [
            {default: "You can see previous"},
            {success: "Updates"},
            {default: "to the plataform by clicking on"},
            {success: "System Version"},
            {default: "down below"},
          ],
        },
        {
          id: 12,
          content: [
            {default: "The agent"},
            {success: "Debug Payload"},
            {default: "prints all "},
            {success: "internal variables"},
            {default: "when executed"},
          ],
        },
        {
          id: 13,
          content: [
            {default: "You can"},
            {success: "Manually trigger"},
            {default: "any"},
            {success: "Flux"},
            {default: "with a "},
            {success: "Request"},
            {default: " - see more info on the"},
            {success: "Webhook page"},
          ],
        },
        {
          id: 14,
          content: [
            {default: "You can"},
            {success: "Manually trigger"},
            {default: "any"},
            {success: "Flux"},
            {default: "with a "},
            {success: "Request"},
            {default: " - see more info on the"},
            {success: "Webhook page"},
          ],
        },
        {
          id: 15,
          content: [
            {default: "Commonly used"},
            {success: "requests"},
            {default: "can be"},
            {success: "easily re-cycled"},
            {default: "using the"},
            {success: "Integrator"}
          ],
        },      
        {
          id: 16,
          content: [
            {default: "You can perform"},
            {success: "Web Scraping"},
            {default: "using a "},
            {success: "JSON Requester"},
          ],
        },        
        {
          id: 17,
          content: [
            {success: "Disabled Middlewares"},
            {default: "will be"},
            {success: "ignored"},
            {default: "in all executions"},
          ]    
        },
        {
          id: 18,
          content: [
            {default: "You can set a transmission to"},
            {success: "inactive"},
            {default: "if it's no longer used"},
          ],
        },    
        {
          id: 19,
          content: [
            {default: "The"},
            {success: "Propagator"},
            {default: "although very"},
            {success: "powerful"},
            {default: "can be very "},
            {success: "problematic"},
          ],
        },
        {
          id: 20,
          content: [
            {default: "Any created"},
            {success: "Timestamp"},
            {default: "in a Flux will be created in"},
            {success: "UTC format"},
          ],
        },
        {
          id: 21,
          content: [
            {default: "You can set"},
            {success: "Advanced Filters"},
            {default: "to better visualize logs on the"},
            {success: "Dashboard"},
          ],
        },
        {
          id: 22,
          content: [
            {default: "You can manage "},
            {success: "Permissions"},
            {default: "on the "},
            {success: "Service"},
            {default: "and"},
            {success: "Event pages"},
          ],
        },
        {
          id: 23,
          content: [
            {default: "It's better to initialize"},
            {success: "Temps"},
            {default: "with"},
            {success: "new Object($.TEMP_X)"},
            {default: "instead of "},
            {success: "$.TEMP_X = { } "},
          ],
        },
      ],
      this.random = getRandomItem(this.items)
  }
}