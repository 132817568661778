<template>
  <div>

    <b-modal
      ref="modal-generate-report"
      centered
      ok-only
      :ok-disabled="!selectedTransmissions || selectedTransmissions.length < 1 || loading"
      @ok="generateReport()"
      @hide="(e)=>{if (loading) e.preventDefault()}"
    >
      <template #modal-title>
        <div>
          <b-icon icon="clipboard-data" class="mr-25"/> Generate Organization Report
        </div>
      </template>


      <div v-if="Array.isArray(this.transmissions)">
      
        <div class="d-flex justify-content-between"> 
          <span class="mb-1 d-block">Organization: <b>{{getThisOrg().name}}</b></span>
          <div>
            <b-button class="p-25" variant="none" id="generate-report-day-picker">
              {{pickedDate.month}}
            </b-button>
             <span class="text-muted font-weight-bold">/</span>
            <b-button class="p-25" variant="none" id="generate-report-year-picker">
              {{pickedDate.year}}
            </b-button>
            <b-icon icon="calendar" variant="secondary" class="mx-50"/>

              <!-- ======= DATE PICKER =========  -->

              <b-tooltip target="generate-report-day-picker" triggers="focus" variant="dark-blue" placement="left">
                <div class="d-flex">
                  <div class="mr-25">
                    <b-button 
                      v-for="i in [1,3,5,7,9,11]" 
                      :key="'month-picker-btn-'+i"
                      :variant="pickedDate.month == i ? 'secondary': 'outline-secondary'"
                      class="p-50 d-block w-100 mb-25"
                      @click="pickedDate.month= i"
                    >
                      {{monthMap[i]}}
                    </b-button>
                  </div>
                  <div>
                    <b-button 
                      v-for="i in [2,4,6,8,10, 12]" 
                      :key="'month-picker-btn-'+i"
                      :variant="pickedDate.month == i ? 'secondary': 'outline-secondary'"
                      class="p-50 d-block w-100 mb-25"
                      @click="pickedDate.month=i"
                      
                    >
                      {{monthMap[i]}}
                    </b-button>
                  </div>
                </div>
              </b-tooltip>

              <b-tooltip target="generate-report-year-picker" triggers="focus" variant="dark-blue" placement="right">
                <b-form-select :options="yearOptions" v-model="pickedDate.year"></b-form-select>
              </b-tooltip>

              <!-- ======= DATE PICKER =========  -->


          </div>
        </div>

        <h4 class="mb-25">Transmissions:</h4>
        <div class="bg-dark-blue rounded py-50 px-50 transmission-container">
          <template v-for="(itm, idx) in transmissions">

            <b-button class="transmission-item position-relative text-left" :class="isSelected(itm) ? '' : 'low-opacity'" variant="none" @click="toggleSelect(itm)">

              <b-icon icon="check" variant="success" scale="1.3" class="mr-50 d-inline" :class="isSelected(itm) ? '' : 'invisible'"/>
              <h5 class="m-0 d-inline">{{itm.name}}</h5>

              <span v-if="itm.active" class="small text-secondary ml-50 text-success font-weight-bold" >
                active
               <b-icon icon="circle-fill" variant="success" class="d-inline" scale="0.3" shift-v="4" shift-h="-5"/>
              </span>
              <span v-else class="small text-secondary ml-50 text-danger font-weight-bold">
                inactive 
                <b-icon icon="circle-fill" variant="danger" class="d-inline" scale="0.3" shift-v="4" shift-h="-5"/>
              </span>
            </b-button>

          </template>
        </div>
      </div>
      <div v-else class="py-2" align="center">
        <b-spinner variant="success"/>
      </div>

      <template #modal-ok>
        <div v-if="!loading" class="text-black">
          Generate Report
        </div>
        <b-spinner small v-else/>
      </template>
    </b-modal>
  </div>
</template>


<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Carousel, Slide } from 'vue-carousel';
import moment from 'moment'

import {
  BCard,
  BCardText,
  BCardBody,
  BLink,
  BButton,
  BCol,
  BRow,
  BSkeleton,
  BSkeletonImg,
  BFormInput,
  BInputGroup, 
  BInputGroupPrepend,
  BBadge,
  BPagination,
  BAvatar,
  BSpinner,
  BFormDatepicker,
  BTooltip,
  BFormSelect
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapGetters } from 'vuex';

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BButton,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    BSkeleton,
    Carousel,
    Slide,
    BSkeletonImg,
    BCardBody,
    BFormInput,
    BInputGroup, 
    BInputGroupPrepend,
    BBadge,
    BPagination,
    BAvatar,
    BSpinner,
    BFormDatepicker,
    BTooltip,
    BFormSelect
  },
  data() {
    return {
      selectedTransmissions: [],
      loading: false,
      monthMap:{
        "1": "Jan",
        "2": "Feb",
        "3": "Mar",
        "4": "Apr",
        "5": "May",
        "6": "Jun",
        "7": "Jul",
        "8": "Aug",
        "9": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
      },
      pickedDate: {
        month: 1,
        year: 2013,
      },
      yearOptions: [],

      chargerData: undefined,
      planData: undefined,
    };
  },
  props: {
    transmissions: {
      type: Array,
      default: undefined
    },
    dinamicOrg:{
      type: Number,
      default: (-1)
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    ...mapGetters(['getSelectedOrganization', 'getUserOrgs'])
  },
  methods: {
    init(){
      let y = new Date().getFullYear()
      let m = new Date().getMonth()
      let years = [];
      let n = 10;

      for (let i = -n; i < 1; i++){
        years.push(y + i)
      }
      this.yearOptions = years;
      this.pickedDate.year = y;
      this.pickedDate.month = m+1;

    },
    show(){
      this.init()


      this.selectedTransmissions = structuredClone(this.transmissions) || []
      this.$refs['modal-generate-report'].show();
    },
    isSelected(itm){
      return !!(this.selectedTransmissions.some(e => e.id == itm.id))
    },
    toggleSelect(itm){
      let idx = this.selectedTransmissions.findIndex(el => el.id == itm.id)
      if (idx > -1){
        this.selectedTransmissions.splice(idx, 1)
      } else {
        this.selectedTransmissions.push(itm)
      }
    },
    getDayWithLeadingZero(d){
      if (parseInt(d) < 10){
        return '0'+d
      }
    },
    fetchCharger(){
      let periond = this.pickedDate.month + "/" + this.pickedDate.year
      return this.$store.dispatch("getOrganizationLogs", {orgID:this.getThisOrg().id, period:periond})
        .then((resp)=>{
          this.chargerData = resp
        })
        .catch((err)=>{
          console.error(err)
        })
    },
    async generateReport(){
      this.loading = true
      
      await Promise.all([this.fetchCharger()])
      //this is if we end up using another request in the future to fetch PlanData


      let charges = structuredClone(this.chargerData)
      let total = {
        requests: 0,
        success: 0,
        successRate: 0,
      }
      let r = []
      this.selectedTransmissions.forEach(el => {
        let transmission = {
          name: el.name,
          status: el.active,
          requests: 0,
          successRequests: 0,
          successRate: 0,
        }

        let tCharger = charges.find(c => c.transmission == el.identifier)
        
        if (tCharger){
          transmission.requests = tCharger.tokens
          transmission.successRate = parseFloat(( tCharger.success_tokens / tCharger.tokens).toFixed(4))
          transmission.successRequests = tCharger.success_tokens
        }
        total.requests = total.requests + transmission.requests 
        total.success = total.success + transmission.successRequests 

        r.push(transmission)
      });
      
      let numOfDaysInMonth = new Date(this.pickedDate.year, this.pickedDate.month, 0).getDate()
      let datePeriod = {
        start: `01/${this.getDayWithLeadingZero(this.pickedDate.month)} 00:01`,
        end: `${numOfDaysInMonth}/${this.getDayWithLeadingZero(this.pickedDate.month)} 23:59`
      }
      total.successRate = parseFloat(( total.success / total.requests).toFixed(4))
      if (isNaN(total.successRate)){
        total.successRate = 0
      }
      
      this.printReport(r , datePeriod, total)
    },
    printReport(data, period, total){
      
      this.loading = false
      this.$refs['modal-generate-report'].hide();

      let orgName = this.getThisOrg().name

      let printWindow = window.open('', 'PRINT', 'height=2000,width=1200');
      let today = moment(new Date()).format('DD/MM/YYYY');
      let tableItemsHtml = ""

      data.forEach(el => {
        
        let itmTemplate = `        
          <div class="row-item">
            <h2 class="table-row-title">INTEGRAÇÃO: ${el.name}</h2>
            <div class="row-content">
              <div class="${el.status ? 'ativa' : 'inativa'}" >${el.status ? 'Ativa' : 'Inativa'}</div>
              <div>${period.start}</div>
              <div>${period.end}</div>
              <div>${el.requests}</div>
              <div>${(el.successRate*100).toFixed(2)}%</div>
              <div>[DESCRIÇÃO]</div>
              <div>Nenhum.</div>
            </div>
          </div>
        `

        tableItemsHtml = tableItemsHtml + itmTemplate
      });

      let htmlCode = `
<html>
  <head>
    <title>Document</title>

    <style>
      
      body, html, main{
        --width: 1200px;

        --colomn-size: calc(100%/9);
        /*================= COLOR SETUP ================= */
        --base-blue: #0b5394;
        --header-bg: #93c47d;

        --table-green: #b7e1cd;
        --table-red: #f4c7c3;
        /*================= COLOR SETUP ================= */
        
        width: var(--width) !important;
        max-width: var(--width) !important;
        min-width: var(--width) !important;
        margin: auto;
      }

      main{
        box-sizing: border-box;
        color: var(--base-blue);
        font-family: Arial;
        letter-spacing: .5px;
        font-size: 12px;
        padding: 15px;
      }

      h1{
        text-transform: uppercase;
        font-size: 30px;
        border-bottom: 2px solid var(--base-blue);
        padding: 5px;
        font-weight: bolder;
      }

      .header-block{
        background-color: var(--header-bg);
        width: 45%;
        color: white;
        font-weight: bold;
        padding: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .table-header-container{
        border: 2px solid var(--base-blue);
        margin-top: 20px;
        font-weight: bold;
        border-collapse: collapse;
        margin-bottom: 20px;
      }
      .table-header-title{
        background-color: var(--base-blue);
        color: white;
        padding: 5px;
      }
      .table-header-column-container{
        width: 100%;
        display: flex;
      }

      .table-header-column{
        width: var(--colomn-size);
      }
      .table-header-column-big{
        width: calc(var(--colomn-size) * 2);
        
      }
      .table-header-column , .table-header-column-big{
        border: 1px solid var(--base-blue);
        padding: 5px 0;
        text-align: center;
        display: block;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }

      .make-uppercase{
        text-transform: uppercase;
      }


      .row-item{
       margin-bottom: 30px; 
      }
      .table-row-title{
        font-size: 20px;
        border-bottom: 1px solid black;
        color: black;
        font-weight: bold;
        margin-bottom: 0;
      }

      .row-content{
        width: 100%;
        display: flex;
        text-align: center;
      }
      .row-content > *{
        width: var(--colomn-size);
        text-transform: capitalize;
        padding: 5px 1px;
      }
      .row-content :nth-child(6) , .row-content :nth-child(7){
        width: calc(var(--colomn-size) * 2);
        text-transform: none;
      }
      .row-content .ativa{
        background-color: var(--table-green);
      }
      .row-content .inativa{
        background-color: var(--table-red);
      }


      .print-btn{
        margin-top: 150px;
        margin-left: 30px;
      }
      .print-guide{
        margin-left: 30px;
      }

      @media print{
        .print-btn , .print-guide{
          display: none;
        }
      }

    </style>
  </head>
  <body> 

    
    <main contenteditable>

      <h1>Parceiro  ${orgName} - Relatório de serviço</h1>
      
      <div class="make-uppercase">  <!-- HEADER -->
        <div style="display: flex; justify-content: space-between; margin-bottom: 3px;">
          <div class="header-block">
            <div>PLANO MENSAL</div>
             <div>ATÉ ??? DE REQUISIÇÕES</div>
          </div>
          <div class="header-block">
            <div>RESPONSÁVEL FIQON</div>
             <div>GUILHERME PIMPÃO CAVALCANTE	</div>
          </div>
        </div>

        <div style="display: flex; justify-content: space-between;">
          <div class="header-block">
            <div>VALOR</div>
             <div>R$ ????,??</div>
          </div>
          <div class="header-block">
            <div>DATA</div>
            <div>${today}</div>
          </div>
        </div>
      </div> <!-- HEADER -->

      <div class="table-header-container make-uppercase">
        <div align="center" class="table-header-title">
          Detalhes do projeto
        </div>
        <div class="table-header-column-container">
          <div class="table-header-column">
            Status
          </div>

          <div class="table-header-column-big" style="padding: 0;">
            <div style="width: 100%; border-bottom: 1px solid var(--base-blue); padding: 3px 0;">Período Avaliado</div>
            <div style="display: flex; width: 100%; align-items: center;">
              <div style="width: 50%; border-right: 1px solid var(--base-blue); padding-top: 5px;">início</div>
              <div style="width: 50%; padding-top: 5px;">término</div>
            </div>
          </div>

          <div class="table-header-column">
            número de inscrições
          </div>
          <div class="table-header-column">
            taxa de sucesso (%)
          </div>
          <div class="table-header-column-big">
            descrição
          </div>
          <div class="table-header-column-big">
            comentários adicionais
          </div>
        </div>
      </div>


      <!-- ====================== TABLE ======================-->
      <div>

        ${tableItemsHtml}



      </div>
      <!-- ====================== TABLE ======================-->
      

      <div style="width: 50%;" class="make-uppercase">
        <h2 style="color: black; font-size: 20px;">Número total de integrações ativas</h2>
        <div style="background-color: var(--base-blue); display: flex; justify-content: space-between; color: white; padding: 3px 20px; font-weight: bold; text-align: center;">
          <div style="width: 50%;">período</div>
          <div style="width: 25%;">número</div>
          <div style="width: 25%;">taxa média</div>
        </div>

        <div style="display: flex; text-align: center; padding: 4px 0;">
          <div style="width: 50%; display: flex; justify-content: space-evenly;">
            <div>${period.start}</div>
            <div>${period.end}</div>
          </div>
          <div style="width: 25%;">${total.requests}</div>
          <div style="width: 25%;">${(total.successRate * 100).toFixed(2)}%</div>
        </div>
      </div>
    </main>


    <button class="print-btn" onclick="window.print()">IMPRIMIR</button>
    <div class="print-guide">
      <h4>Preferidas Opções de Impressão:</h4>
      <li> Ativar gráficos de segundo plano / Cor de Fundo </li>
      <li> Margens -> Nenhuma </li>
    </div>
  </body>
</html>`
      printWindow.document.write(htmlCode);
      printWindow.document.close();
      printWindow.focus();
    },
    getThisOrg(){
      if (this.dinamicOrg != -1){
        return {id:this.dinamicOrg}
      }
      if (!this.getSelectedOrganization || !this.getUserOrgs){
        return
      }
      return this.getUserOrgs.find(el => el.id == this.getSelectedOrganization)
    }
    
  },
};
</script>

<style lang="scss" scoped>

.transmission-container{
  max-height: 300px;
  overflow-y: auto;
  .transmission-item{
    display: block;
    width: 100%;
    padding: 2px 5px;
    margin: 2px 0;
    &:hover{
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

.low-opacity{
  opacity: 0.3;
}


@media print {
   .transmission-container{
    z-index: 20000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
   }
}
</style>
