<template>
  <div :style="`--bg-img: url( ${ getImage(`transmission-image-${item.identifier}`) } );`">
      
    <b-card
      body-class="p-0"
    >
      <div class="transmission-img">
        <div class="image-bg"/>
        <div class="image-content"/>
      </div>

      <div class="p-75">
        <b-card-text class="d-block">
          <h2>
            {{transmissionName }}
          </h2> 

        </b-card-text>
          <b-row no-gutters class="d-flex justify-content-between">
            <b-col class="text-secondary">

              <span class="d-block mb-25">
                <b-badge variant="light-success" v-if="item.active">
                  <span class="middleware-status active mr-50"/>
                  <span class="small font-weight-bolder text-uppercase">{{$t('common.terms.active')}}</span>
                </b-badge>

                <b-badge variant="light-danger" v-else> 
                  <span class="middleware-status inactive mr-50"/>
                  <span class="small font-weight-bolder text-uppercase">{{$t('common.terms.inactive')}}</span>
                </b-badge>
              </span>

              <span class="mt-2 smaller-text">
                {{ $t('common.terms.created') }}
                <span class="font-weight-bold">
                  {{ treatTime(item.created_at) }} 
                </span>
              </span>
            </b-col>

            <b-col class="d-flex justify-content-end">
              <div class="d-flex my-25">
                <div>
                  <b-button 
                    variant="outline-secondary" 
                    class="mx-50 px-50"  
                    :to="{ name: 'middlewares', params: { transmissionID: item.id }  }"
                  >
                    <feather-icon class="" icon="GitPullRequestIcon"/>
                  </b-button>
                </div>
                <div>
                  <b-button 
                    variant="success" 
                    class="d-block"
                    :to="{ name: 'transmission-dashboard', params: { transmissionID: item.id }  }"
                  > 
                    {{ $t('common.terms.access')}}
                  </b-button>
                </div>
              </div>
            </b-col>
            
          </b-row>

          <!-- <div class="sparser-letters">
            <b-badge v-if="item.active" variant="light-success" class="w-100 square rounded" >ACTIVE</b-badge>
            <b-badge v-else variant="light-danger" class="w-100 square rounded">INACTIVE</b-badge>
          </div> -->
        <span class="development-status-flag" :class="{'is_chargeable' : item.is_chargeable}">
          <b-icon :icon="item.is_chargeable ? 'check' : 'code' " shift-v="-0.5" scale="1.2"/>
        </span>
      </div>
    </b-card>
    <b-tooltip :target="getID('transmission-name')">
      <span>{{ this.item.name }}</span>
    </b-tooltip>
    <b-tooltip :target="getID('development-status')" placement="right">
      <span v-if="!item.is_chargeable">In development</span>
      <span v-else>Deployed</span>
    </b-tooltip>
  </div>
</template>

<script>

import moment from 'moment'

import { 
  BCard,
  BCardText,
  BButton, 
  BRow, 
  BCol, 
  BTooltip, 
  BLink, 
  BBadge,
  BAvatar
} from 'bootstrap-vue';

import VueContext from 'vue-context'
import { makeToast } from '@/layouts/components/Popups'
import { v4 as uuidv4 } from "uuid";
import {mapGetters} from "vuex"

  export default {
    components: {
      BCard, 
      BCardText,
      BButton,
      BRow,
      BCol,
      BTooltip,
      VueContext,
      BLink,
      BBadge,
      BAvatar
    },
    data() {
      return {
        uuidMap: {},
      }
    },  
    props: {
      value: {
        type: Object,
        default: null,
      },
    },
    computed: {
      ...mapGetters(['getImage']),
      item: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        }
      },
      transmissionName() {
        return this.item.name.length < 27 ? this.item.name : `${this.item.name.substring(0,28)}...`
      },
      transmissionOptions() {
        return [
          { title: "Go to Middlewares", icon: "GitPullRequestIcon", action: "middlewares", params: { transmissionID: this.item.id }  }
        ]
      }
    },
    methods: {
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      treatTime(time){
        return new moment(time).locale('pt').format('l')
      }
    }
  }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
.vue-context-menu{
  position: absolute;
}
</style>


<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';


.transmission-img{
  position: relative;
  height: 100px;
  width: 100%;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  .image-bg{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparentize($light, 0.9);
    background-size: cover;
    background-position: center;
    background-image: var(--bg-img) !important;
    filter: blur(4px) saturate(1.5);
  }

  .image-content{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: var(--bg-img) !important;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    $shadow: 15px transparentize(black, 0.5) ;
    filter: drop-shadow(0px 1px $shadow) drop-shadow(0px -1px $shadow);
  }
  
}


.sparser-letters{
  letter-spacing: 2px !important;
}
.transition-name-container{
  max-height: 29px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.middleware-status{
  border-radius: 100px;
  display: inline-block;
  padding: 4px;

  &.active{
    background-color: var(--success);
  }
  &.inactive{
    background-color: var(--danger);
  }
}

.smaller-text{
  font-size: 11px;
}


.development-status-flag{
  position: absolute;
  padding: 0 3px;
  left: 0;
  top: 0;
  z-index: 2;
  background-color: $warning;
  color: $black;
  border-radius: 4px 0px 8px 0px;
  font-size: 12px;
  border-right: 1px solid $black;
  border-bottom: 1px solid $black;
  opacity: 0.5;
  &.is_chargeable{
    background-color: $success;
  }
}


</style>